import "react-dates/initialize";
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { DateRangePicker, SingleDatePicker, DayPickerRangeController, isInclusivelyBeforeDay } from "react-dates";
import { START_DATE, END_DATE } from 'react-dates/constants';
import "react-dates/lib/css/_datepicker.css";

import moment from "moment";

class DateRangeCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment(this.props.initialStartDate, "YYYY-MM-DD"),
      endDate: moment(this.props.initialEndDate, "YYYY-MM-DD"),
      focusedInput: null
    };
  }

  render() {
    return(
      <div>
        <DateRangePicker
          startDate={this.state.startDate}
          startDateId="start_date"
          endDate={this.state.endDate}
          endDateId="end_date"
          readOnly
          onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
          focusedInput={this.state.focusedInput}
          onFocusChange={focusedInput => this.setState({ focusedInput })}
          minimumNights={0}
          isOutsideRange={day => this.props.isOutsideRange ? !isInclusivelyBeforeDay(day, moment(this.props.maxDate) ) : false }
          displayFormat='YYYY-MM-DD'
          monthFormat='MMMM YYYY'
        />
      </div>
    )
  }
}

DateRangeCalendar.propTypes = {
  minimumNights: PropTypes.number,
  isOutsideRange: PropTypes.bool,
  initialStartDate: PropTypes.string,
  initialEndDate: PropTypes.string
};

export default DateRangeCalendar;
