// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.

import React from 'react'
import axios from 'axios';

const EventList = (props) => {
  const {
    name,
    time,
    timeZone,
  } = props;

  const timeString = new Date(time).toLocaleString(
    'en-US',
    {
      timeZoneName: 'short',
    }
  );

  return(
    <li className="list-group-item">
      <strong>Task {name}</strong>
      <br></br>
      <span>{timeString}</span>
    </li>
  )
}

class OnfleetEvents extends React.Component {
  _isMounted = false;

  state = {
    loaded: false,
    events: [],
    task: null,
    error: false,
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadActivitiesFromServer()
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
      return;
    };
  }

  loadActivitiesFromServer = () => {
    if (this._isMounted) {
      axios.get(`/api/onfleet_tasks/${this.props.taskId}`)
      .then((response) => {
        this.setState({ events: response.data.completion_details.events, task: response.data, loaded: true })
      })
      .catch((error) => {
        // this.setState({error: true, loaded: true})
      });
    }
  }

  render() {
    const listComponents = this.state.events.map((event) => (
      <EventList
        key={event.name}
        name={event.name}
        time={event.time}
        timeZone={this.props.timeZone}
      />
    ))

 
    let imageUrl = null;


    if (this.state.task) {
      listComponents.unshift(
        <EventList
          key='created'
          name='created'
          time={this.state.task.time_created}
          timeZone={this.props.timeZone}
        />
      )

      const completionDetails = this.state.task.completion_details;

      if (Object.prototype.hasOwnProperty.call(completionDetails, 'success')) {
        const completed_title = this.state.task.completion_details.success ? 'completed' : 'failed'
        const successTask = (
          <EventList
            key='completion'
            name={completed_title}
            time={this.state.task.completion_details.time}
            timeZone={this.props.timeZone}
          />
        )
        listComponents.push(successTask)
      }

      if (Object.prototype.hasOwnProperty.call(completionDetails, 'photoUploadId')) {
        imageUrl = completionDetails.photoUploadId
      }
    }

    return (
      <React.Fragment>
        <div className="panel-heading">
          Timeline

          <code className="pull-right">{this.props.shortTaskId}</code>
        </div>

        <ul className="list-group"> 
          {listComponents}
        </ul>

        { imageUrl &&
          <img src={`https://d15p8tr8p0vffz.cloudfront.net/${imageUrl}/800x.png`} width='300px' alt="Logo" />
        }
      </React.Fragment>
    )
  }
}

export default OnfleetEvents;
