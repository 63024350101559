import React from "react"

const Checkbox = (props) => {
  return(
    <div>
      <label htmlFor={props.name}>
        <input
          className="mbs"
          style={props.inputStyle}
          id={props.id}
          name={props.name}
          type="checkbox"
          checked={props.isSelected ? "1" : 0}
          onChange={props.onChange} />
        {props.title}
      </label>
    </div>
  );
}

export default Checkbox;
