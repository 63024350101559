import React from "react";
import { FormInput } from "../formComponents/FormInput";
import { FormSelect } from "../formComponents/FormSelect";
import { GroupedFormSelect } from "../formComponents/GroupedFormSelect";


class CateringForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initialState()
        this.handleFormInput = this.handleFormInput.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleAddressSelect = this.handleAddressSelect.bind(this)
        this.autocomplete = null
        this.redirect = this.redirect.bind(this)
    }

    initialState() {
        return {
            //required
            recipientName: "",
            recipientPhone: "",
            address1: "",
            city: "",
            state: "",
            zip: "",
            fulfillmentLocationId: "",
            window_starts_at: "",
            window_ends_at: "",
            pickup_window_starts_at: "",
            pickup_window_ends_at: "",
            external_catering_id: "",
            quantity: "",
            //optional
            address2: "",
            deliveryInstructions: "",
            serviceTime: "",
            driverId: "",
            payout_amount: "",
            formValid: false,
            formErrors: "",
            errorMessage: "",
            lat: "",
            lng: "",
            disabled: false,
            btnText: "Place Order"
        }
    }

    componentDidMount() {
        this.autocomplete = new google.maps.places.Autocomplete(document.getElementById("autocomplete-address"), {})
        this.autocomplete.addListener("place_changed", this.handleAddressSelect)
    }

    handleFormInput = event => {
        this.setState({ [event.target.name]: event.target.value })
    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({ disabled: true })
        if (this.validateForm()) {
           this.postData() 
        } else {
            this.setState({ disabled: false, btnText: "Place Order"})
        }
          
    }

    postData = async () => {
        const {
            recipientName: recipient_name,
            recipientPhone: recipient_phone,
            address1: address_1,
            city: city,
            state: state,
            zip: zip,
            fulfillmentLocationId: fulfillment_location_id,
            external_catering_id: external_catering_id,
            address2: street_2,
            deliveryInstructions: delivery_instructions,
            serviceTime: service_time,
            driverId: driver_id,
            lat: lat,
            lng: lng,
        } = this.state

        const street_1 = this.state.address1
        const quantity = parseInt(this.state.quantity)
        const payout_amount = parseInt(this.state.payout_amount)
        const window_starts_at = this.state.window_starts_at.split("T").join(" ")
        const window_ends_at = this.state.window_ends_at.split("T").join(" ")
        const pickup_window_ends_at = this.state.pickup_window_ends_at.split("T").join(" ")
        const pickup_window_starts_at = this.state.pickup_window_starts_at.split("T").join(" ")
        const arr = this.state.window_starts_at.split("T")
        const orderDate = arr[0]

        const order = {
            recipient_name,
            quantity,
            recipient_phone,
            delivery_instructions,
            city,
            state,
            zip,
            window_starts_at,
            window_ends_at,
            pickup_window_starts_at,
            pickup_window_ends_at,
            external_catering_id,
            fulfillment_location_id,
            driver_id,
            service_time,
            payout_amount,
            address_attributes: {
              street_1,
              street_2,
              city,
              state,
              zip,
              lat,
              lng
            }
        }

        const csrfToken = document.querySelector("meta[name='csrf-token']").content;
        
        const response = await fetch("/sweetgreen/catering/orders", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-CSRF-Token": csrfToken
            },
            body: JSON.stringify(order),
            credentials: "same-origin"
        });

        const result = await response.json();

        if (response.ok) {
            this.setState({ errorMessage: "Catering order successfully created.", disabled: false, btnText: "Order Successful!" })
            this.redirect(orderDate)
        } else {
            let resError = result.errors[1][1]
            const resMessage = resError.split(",")       
            this.setState({ errorMessage: `Catering order could not be created. ${resMessage[1]}`, disabled: false, btnText: "Place Order" })
            window.scrollTo(0, 0)
        }
        
        return response;
   
    }

    redirect(date) {
        window.location.href = `/sweetgreen/catering/orders?delivery_date=${date}`
    }
    handleAddressSelect() {
        let addressObject = this.autocomplete.getPlace()
        let completeAddress = addressObject.formatted_address.split(",")
        let stateZip = completeAddress[2].split(" ")
        let state = stateZip[1]
        let zip = stateZip[2]
        this.setState({
            address1: `${completeAddress[0]}`,
            city: `${completeAddress[1]}`,
            state: state,
            zip: zip,
            lat: addressObject.geometry.location.lat(),
            lng: addressObject.geometry.location.lng(),
        })
    }


    validateForm() {
        let formErrors = {}
        let formValid = true
        if (this.state.recipientName.length === 0) {
            formErrors.recipientName = ["Recipient Name can't be blank."]
            formValid = false
        }
        if (this.state.recipientPhone.length === 0) {
            formErrors.recipientPhone = ["Recipient Phone can't be blank."]
            formValid = false
        }
        if (this.state.address1.length === 0) {
            formErrors.address1 = ["Address 1 can't be blank."]
            formValid = false
        }
        if (this.state.city.length === 0) {
            formErrors.city = ["City can't be blank."]
            formValid = false
        }
        if (this.state.state.length === 0) {
            formErrors.state = ["State can't be blank."]
            formValid = false
        }
        if (this.state.zip.length === 0) {
            formErrors.zip = ["Zip can't be blank."]
            formValid = false
        }
        if (this.state.fulfillmentLocationId.length === 0) {
            formErrors.fulfillmentLocationId = ["Must select a location."]
            formValid = false
        }
        if (this.state.window_starts_at.length === 0) {
            formErrors.window_starts_at = ["Must select date and time."]
            formValid = false
        }
        if (this.state.window_ends_at.length === 0) {
            formErrors.window_ends_at = ["Must select date and time."]
            formValid = false
        }
        if (this.state.external_catering_id.length === 0) {
            formErrors.external_catering_id = ["Monkey Order Id can't be blank."]
            formValid = false
        }
        if (this.state.quantity.length === 0) {
            formErrors.quantity = ["Quantity can't be blank."]
            formValid = false
        }
        if (this.state.pickup_window_starts_at.length === 0) {
            formErrors.pickup_window_starts_at = ["Must select date and time."]
            formValid = false
        }
        if (this.state.pickup_window_ends_at.length === 0) {
            formErrors.pickup_window_ends_at = ["Must select date and time."]
            formValid = false
        }
        this.setState({ formValid: formValid, formErrors: formErrors })

        return formValid
        
    }

    formValidation = event => {
        const {name} = event.target
        if (event.target.value === "") {
            this.setState({formErrors: {[name]: "Can't be blank."} })
        } else {
            this.setState({formErrors: {[name]: ""} })
        }
    }

    render() {
        const locations = this.props.locations.map(type => {
            return { value: type.id, label: type.name, optgroup: type.address.state }  
        });
        const locationTypes = {};

        locations.forEach(option => {
          if (!locationTypes[option.optgroup]) locationTypes[option.optgroup] = [];
          locationTypes[option.optgroup].push({
            value: option.value,
            label: option.label
          });
        });

        const drivers = this.props.drivers.map(driver => {
            return { value: driver.id, label: driver.name}
        })

        return (
            <React.Fragment>
                <div>
                    {this.state.errorMessage != "" ? <div className="alert alert-info alert-dismissible" role="alert">
                        {this.state.errorMessage}
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div> : <></>}
                    <div style={{paddingTop: "2rem"}}>
                      <FormInput
                            label="Recipient Name *"
                            type="text"
                            className="form-control"
                            name="recipientName"
                            value={this.state.recipientName}
                            onChange={this.handleFormInput}
                            onBlur={this.formValidation}
                        />
                        <span style={{color: "red"}}>{this.state.formErrors.recipientName}</span>
                    </div>
                    <div style={{paddingTop: "2rem"}}>
                        <FormInput
                            label="Recipient Phone *"
                            type="text"
                            className="form-control"
                            name="recipientPhone"
                            value={this.state.recipientPhone}
                            onChange={this.handleFormInput}
                            onBlur={this.formValidation}
                        />
                        <span style={{color: "red"}}>{this.state.formErrors.recipientPhone}</span>
                    </div>
                    <div style={{paddingTop: "2rem"}}>
                        <FormInput
                            label="Address 1 *"
                            type="text"
                            id="autocomplete-address"
                            className="form-control"
                            name="address1"
                            value={this.state.address1}
                            onChange={this.handleFormInput}
                            onBlur={this.formValidation}
                        />
                        <span style={{color: "red"}}>{this.state.formErrors.address1}</span>
                    </div>
                    <div style={{paddingTop: "2rem"}}>
                        <FormInput
                            label="Address 2:"
                            type="text"
                            className="form-control"
                            name="address2"
                            value={this.state.address2}
                            onChange={this.handleFormInput}
                        />
                    </div>    
                    <div style={{paddingTop: "2rem"}}>
                        <FormInput
                            label="City *"
                            type="text"
                            className="form-control"
                            name="city"
                            value={this.state.city}
                            onChange={this.handleFormInput}
                            onBlur={this.formValidation}
                        />
                        <span style={{color: "red"}}>{this.state.formErrors.city}</span>
                    </div>    
                    <div style={{paddingTop: "2rem"}}>
                        <FormInput
                            label="State *"
                            type="text"
                            className="form-control"
                            name="state"
                            value={this.state.state}
                            onChange={this.handleFormInput}
                            onBlur={this.formValidation}
                        />
                        <span style={{color: "red"}}>{this.state.formErrors.state}</span>
                    </div>
                    <div style={{paddingTop: "2rem"}}>
                        <FormInput
                            label="Zip *"
                            type="text"
                            className="form-control"
                            name="zip"
                            value={this.state.zip}
                            onChange={this.handleFormInput}
                            onBlur={this.formValidation}
                        />
                        <span style={{color: "red"}}>{this.state.formErrors.zip}</span>
                    </div>    
                    <div style={{paddingTop: "2rem"}}>
                        <GroupedFormSelect
                            label="Fulfillment Restaurant *"
                            name="fulfillmentLocationId"
                            className="form-select form-control"
                            value={this.state.fulfillmentLocationId}
                            onChange={this.handleFormInput}
                            groupedOptions={locationTypes}
                            placeholder="Select Location"
                            onBlur={this.formValidation}
                        />
                        <span style={{color: "red"}}>{this.state.formErrors.fulfillmentLocationId}</span>
                    </div>
                    <div style={{paddingTop: "2rem"}} className="col-md-6">
                        <FormInput
                            label=" Pickup Window Starts At *"
                            type="datetime-local"
                            className="form-control"
                            name="pickup_window_starts_at"
                            value={this.state.pickup_window_starts_at}
                            onChange={this.handleFormInput}
                            onBlur={this.formValidation}
                        />
                        <small className="form-text text-muted">Time must be the local time of the fulfillment restaurant.</small>
                        <div style={{color: "red"}}>{this.state.formErrors.pickup_window_starts_at}</div>
                    </div>
                    <div style={{paddingTop: "2rem", paddingBottom: "2rem"}} className="col-md-6">
                        <FormInput
                            label="Pickup Window Ends At *"
                            type="datetime-local"
                            className="form-control"
                            name="pickup_window_ends_at"
                            value={this.state.pickup_window_ends_at}
                            onChange={this.handleFormInput}
                            onBlur={this.formValidation}
                        />
                        <small className="form-text text-muted">Time must be the local time of the fulfillment restaurant.</small>
                        <div style={{color: "red"}}>{this.state.formErrors.pickup_window_ends_at}</div>
                    </div>
                    <div className="col-md-6">
                        <FormInput
                            label="Delivery Window Starts At *"
                            type="datetime-local"
                            className="form-control"
                            name="window_starts_at"
                            value={this.state.window_starts_at}
                            onChange={this.handleFormInput}
                            onBlur={this.formValidation}
                        />
                        <small className="form-text text-muted">Time must be the local time of the fulfillment restaurant.</small>
                        <div style={{color: "red"}}>{this.state.formErrors.window_starts_at}</div>
                    </div>
                    <div style={{paddingBottom: "2rem"}} className="col-md-6">
                        <FormInput
                            label="Delivery Window Ends At *"
                            type="datetime-local"
                            className="form-control"
                            name="window_ends_at"
                            value={this.state.window_ends_at}
                            onChange={this.handleFormInput}
                            onBlur={this.formValidation}
                        />
                        <small className="form-text text-muted">Time must be the local time of the fulfillment restaurant.</small>
                        <div style={{color: "red"}}>{this.state.formErrors.window_ends_at}</div>
                    </div>
                    <div style={{paddingTop: "2rem"}}>
                        <FormInput
                            label="Monkey Order Id *"
                            type="text"
                            className="form-control"
                            name="external_catering_id"
                            value={this.state.external_catering_id}
                            onChange={this.handleFormInput}
                            onBlur={this.formValidation}
                        />
                        <span style={{color: "red"}}>{this.state.formErrors.external_catering_id}</span>
                    </div>
                    <div style={{paddingTop: "2rem"}}>
                        <FormInput
                            label="Quantity *"
                            type="number"
                            className="form-control"
                            name="quantity"
                            value={this.state.quantity}
                            onChange={this.handleFormInput}
                            onBlur={this.formValidation}
                        />
                        <small className="form-text text-muted">The number of items in an order.</small>
                        <div className="invalid-feedback" style={{color: "red"}}>{this.state.formErrors.quantity}</div>
                    </div>
                    <div style={{paddingTop: "2rem"}}>
                        <label>Delivery Instructions</label>
                        <textarea
                            type="text"
                            className="form-control"
                            name="deliveryInstructions"
                            value={this.state.deliveryInstructions}
                            onChange={this.handleFormInput}
                        />
                    </div>    
                    <div style={{paddingTop: "2rem"}}>
                         <FormInput
                            label="Service Time"
                            type="text"
                            className="form-control"
                            name="serviceTime"
                            value={this.state.serviceTime}
                            onChange={this.handleFormInput}
                        />
                        <small className="form-text text-muted" >Enter the time in minutes the delivery will take.</small>
                    </div>
                    <div style={{paddingTop: "2rem"}}>
                        <FormSelect
                            label="Driver"
                            className="form-select form-control"
                            name="driverId"
                            placeholder="Select Driver"
                            value={this.state.driverId}
                            onChange={this.handleFormInput}
                            options={drivers}
                        />
                    </div>
                    <div style={{paddingTop: "2rem"}}>
                        <FormInput
                            label="Payout Amount"
                            type="text"
                            className="form-control"
                            name="payout_amount"
                            value={this.state.payout_amount}
                            onChange={this.handleFormInput}
                        />
                        <small className="form-text text-muted" >Enter payout amount in dollars only, no cents.</small>
                    </div>
                </div>
                <div style={{paddingTop: "2rem", paddingBottom: "1rem"}}>
                    <button className="btn btn-success" type="submit" onClick={this.handleSubmit} disabled={this.state.disabled} >{this.state.disabled ? "Submitting Order..." : this.state.btnText }</button>
                </div>  
            </React.Fragment>
        )
    }
}

export default CateringForm;
