import React from 'react';
import PropTypes from 'prop-types';

export const FormInput = ({
    name,
    type,
    value,
    label,
    className,
    id,
    onChange,
    onBlur,
    ...props
}) => {
    return (
        <React.Fragment>
            <label htmlFor={name}>{label}</label>
            <input
                type={type}
                className={className}
                id={id}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                required
            />
        </React.Fragment>
    )
}

FormInput.defaultProps = {
    type: "text",
    classname: "",
}

FormInput.propTypes = {
    type: PropTypes.string,
    className: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
}
