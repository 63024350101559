import React from 'react';
import PropTypes from "prop-types";

export const FormSelect = ({
    name,
    label,
    value,
    onChange,
    options,
    placeholder,
    className,
    onBlur,
    ...props
}) => (
  <React.Fragment>
    <label htmlFor={name}>{label}</label>
    <select
      name={name}
      value={value}
      onChange={onChange}
      className={className}
      onBlur={onBlur}
    >
      {placeholder && (
        <option value="" disabled>
          {placeholder}
        </option>
      )}
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </React.Fragment>
);

FormSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape),
  className: PropTypes.string,
  onBlur: PropTypes.func
};
