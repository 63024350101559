import React from "react"
import PropTypes from "prop-types";

const Price = (props) => {
  if (props.children == null) {
    return null;
  }

  const price = parseFloat(props.children).toLocaleString('en', {
    style: props.showSymbol ? 'currency' : undefined,
    currency: props.showSymbol ? 'USD' : undefined,
    maximumFractionDigits: props.showDecimals ? 2 : 0,
  });

  return <span className={props.className}>{price}</span>
};

Price.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showDecimals: PropTypes.bool,
  showSymbol: PropTypes.bool,
};

Price.defaultProps = {
  showDecimals: true,
  showSymbol: true,
};

export default Price;
