import React from 'react'
import PropTypes from 'prop-types'

export default class Tooltip extends React.Component {
  static propTypes = {
    features: PropTypes.array.isRequired
  };

  render() {
    const { features } = this.props;

    console.log(features)

    const renderFeature = (feature, i) => {
      return (
        <div key={i}>
          <strong className='fontSize-l fontWeight-6'>{feature.properties.title}</strong>
          <br></br>
          <span className='fontSize-m'>ID: {feature.properties.id}</span>
        </div>
      )
    };

    return (
      <div className="flex-parent-inline flex-parent--center-cross flex-parent--column absolute bottom">
        <div className="flex-child p1 bg-white boxShadow-close borderRadius-s">
          {features.map(renderFeature)}
        </div>
        <span className="flex-child color-gray-dark triangle triangle--d"></span>
      </div>
    );
  }
}