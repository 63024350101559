import React, { Component } from "react";
import Portal from './Portal';

class Modal extends Component {
  render () {
    const { children, toggle, on, modalCardStyle } = this.props;
    return (
      <Portal>
        {on && (
          <div className="modal-wrapper">
            <div className="modal-card" style={modalCardStyle}>
              <div className="row no-gutter pull-right">
                <div className="columns shrink">
                  <button className="close-button" onClick={toggle}>
                    &times;
                  </button>
                </div>
              </div>
              <div>{children}</div>
            </div>
            <div className="background" onClick={toggle}>
            </div>
          </div>
        )}
      </Portal>
    )
  }
}

export default Modal;
