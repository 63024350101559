import React from 'react';
import PropTypes from "prop-types";

const renderOptions = options => {
  return options.map(option => {
    return (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    );
  });
};

export const GroupedFormSelect = ({
    name,
    label,
    value,
    onChange,
    groupedOptions,
    placeholder,
    className,
    onBlur,
    ...props
}) => (
  <React.Fragment>
    <label htmlFor={name}>{label}</label>
    <select
      name={name}
      value={value}
      onChange={onChange}
      className={className}
      onBlur={onBlur}
    >
      {placeholder && (
        <option value="" disabled>
          {placeholder}
        </option>
      )}
      {Object.keys(groupedOptions).map((group, index) => {
        return (
        <optgroup key={index} label={group}>
          {renderOptions(groupedOptions[group])}
        </optgroup>
        );
    })};
    </select>
  </React.Fragment>
);

GroupedFormSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  groupedOptions: PropTypes.object,
  className: PropTypes.string,
  onBlur: PropTypes.func
};
